import { useAuth } from './AuthProvider';
import Home from './components/pages/Home';
import RunReportsSidebar from './components/layout/RunReportsSidebar';
import { Box } from '@mui/material';

export default function ProtectedRoute({ pro, children }) {
  const { user, isLoading } = useAuth();

  if (isLoading) return <p>Authenticating...</p>;

  if (!user) return <Home />;
  else {
    return (
      <>
        <Box
          sx={{
            display: 'grid',

            gridTemplateColumns: {
              xs: '100%',
              sm: '22% 78%',
              md: '18% 82%',
            },
            width: '100%',
            height: '100vh',
          }}
        >
          <RunReportsSidebar />
          <Box
            sx={{
              height: '100vh',
              overflow: 'scroll',
            }}
          >
            {children}
          </Box>
        </Box>
      </>
    );
  }
}
