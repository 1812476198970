import { useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const PlaceComponent = ({
  agent = false,
  chat = false,
  addressError = false,
  setAddressError,
}) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const agentRef = useRef();
  const options = {
    componentRestrictions: { country: 'us' },
    fields: ['address_components', 'geometry', 'icon', 'name'],
  };
  useEffect(() => {
    if (!window.google) return undefined;
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options,
    );
  }, []);

  useEffect(() => {
    if (!window.google) return undefined;
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      agentRef.current,
      options,
    );
  }, []);

  if (agent === true && chat === false) {
    return (
      <input
        ref={agentRef}
        id='agent-primary-address'
        placeholder='Enter a Valid Street Address'
        style={{
          boxSizing: 'border-box',
          width: '100%',
          padding: '1rem',
          fontSize: '1rem',
          border: `1px solid ${addressError ? '#ef4444' : '#e5e7eb'}`,
          borderRadius: 'var(--radius)',
          outline: 'none',
          backgroundColor: 'white',
          color: addressError ? '#ef4444' : undefined,
        }}
      />
    );
  } else if (agent === true && chat === true) {
    return (
      <input
        ref={agentRef}
        type='text'
        id='agent-primary-address'
        placeholder='Please input a valid street address'
        style={{
          width: '100%',
          padding: '0.75rem 3rem 0.75rem 1rem',
          border: '1px solid #e5e7eb',
          borderRadius: '9999px',
          fontSize: '0.875rem',
          outline: 'none',
          boxSizing: 'border-box',
          borderColor: addressError ? '#ef4444' : undefined,
          color: addressError ? '#ef4444' : undefined,
        }}
      />
    );
  }

  return (
    <TextField
      inputRef={inputRef}
      id='address'
      placeholder='Enter a Valid Street Address'
      variant='outlined'
      sx={{
        width: '100%',
        color: '#212529',
        input: {
          '&::placeholder': {
            color: '#212529',
            opacity: 2,
            fontWeight: 800,
            fontSize: '0.875rem',
          },
        },
      }}
      InputProps={{
        sx: {
          color: '#212529',
          borderRadius: '1rem',
        },

        startAdornment: (
          <InputAdornment>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default PlaceComponent;
