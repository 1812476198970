import { Check, KeyboardArrowDown, Map, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const mapOptions = [
  { id: 'discern', label: 'DISCERN (default maps)' },
  { id: 'household', label: 'Household formation forecast' },
  { id: 'unit-growth', label: 'Unit growth vs. population growth' },
  { id: 'rent-heatmap', label: 'Rent Heatmap' },
  { id: 'floodzone', label: 'Floodzone' },
];

export function MapOptionsDropdown({
  selectedOptions,
  onOptionChange,
  onCustomSearch,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [customSearch, setCustomSearch] = useState('');

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionToggle = optionId => {
    if (optionId === 'discern') return; // Prevent toggling DISCERN

    const updatedOptions = selectedOptions.includes(optionId)
      ? selectedOptions.filter(id => id !== optionId)
      : [...selectedOptions, optionId];

    // Ensure DISCERN is always included
    if (!updatedOptions.includes('discern')) {
      updatedOptions.push('discern');
    }

    onOptionChange(updatedOptions);
  };

  const handleCustomSearch = () => {
    if (customSearch.trim()) {
      onCustomSearch(customSearch.trim());
      setCustomSearch('');
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant='text'
        size='small'
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        startIcon={<Map fontSize='small' />}
        sx={{
          height: 36,
          color: 'text.secondary',
          textTransform: 'none',
        }}
      >
        Maps
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: { width: 300 },
        }}
      >
        <Box sx={{ p: 0 }}>
          <Typography variant='subtitle2' sx={{ p: 2, pb: 1 }}>
            Map Options
          </Typography>
          <List dense>
            {mapOptions.map(option => (
              <ListItem
                key={option.id}
                onClick={() =>
                  option.id === 'discern' ? null : handleOptionToggle(option.id)
                }
                disabled={option.id !== 'discern'}
                sx={{ opacity: option.id === 'discern' ? 0.75 : 1 }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <Checkbox
                    edge='start'
                    checked={
                      option.id === 'discern' ||
                      selectedOptions.includes(option.id)
                    }
                    disabled={option.id !== 'discern'}
                    size='small'
                  />
                </ListItemIcon>
                <ListItemText
                  primary={option.label}
                  secondary={option.id !== 'discern' ? 'Coming Soon!' : null}
                />
              </ListItem>
            ))}
          </List>
          <Divider />
          <Typography variant='subtitle2' sx={{ p: 2, pb: 1, opacity: 0.75 }}>
            Custom
          </Typography>
          <Box sx={{ px: 2, pb: 2, display: 'flex' }}>
            <TextField
              placeholder='Coming Soon!'
              value={customSearch}
              onChange={e => setCustomSearch(e.target.value)}
              size='small'
              fullWidth
              disabled
              sx={{ opacity: 0.75 }}
              InputProps={{
                endAdornment: customSearch.trim() && (
                  <IconButton
                    size='small'
                    onClick={handleCustomSearch}
                    edge='end'
                    disabled
                  >
                    <Search fontSize='small' />
                  </IconButton>
                ),
              }}
            />
          </Box>
          {customSearch.trim() && (
            <ListItem button onClick={handleCustomSearch} disabled>
              <ListItemIcon>
                <Check fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={`Search for "${customSearch}"`}
                sx={{ opacity: 0.75 }}
              />
            </ListItem>
          )}
        </Box>
      </Popover>
    </>
  );
}
