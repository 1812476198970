import React, { useEffect, useRef, useState } from 'react';
import { loadModules } from 'esri-loader';
import marker from '../../../CustomVestmapViewer/assets/marker center.png';
import { Box, CircularProgress } from '@mui/material';

function LiveMap({
  webmapID,
  token,
  addressInfo,
  selectedBlock = null,
  defaultSettings = true,
}) {
  const mapRef = useRef(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(() => {
    let view;
    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/FeatureLayer',
      'esri/widgets/Legend',
      'esri/config',
      'esri/smartMapping/statistics/classBreaks',
      'esri/WebMap',
      'esri/geometry/Extent',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
    ]).then(
      ([
        Map,
        MapView,
        FeatureLayer,
        Legend,
        esriConfig,
        classBreaks,
        WebMap,
        Extent,
        Graphic,
        GraphicsLayer,
      ]) => {
        if (!webmapID || !token) return;
        esriConfig.apiKey = token;

        let webmap = null;
        const extent = new Extent({
          xmin: addressInfo?.address_xmin,
          ymin: addressInfo?.address_ymin,
          xmax: addressInfo?.address_xmax,
          ymax: addressInfo?.address_ymax,
          spatialReference: {
            wkid: addressInfo?.spatialReference?.wkid,
          },
        });

        if (defaultSettings) {
          webmap = new WebMap({
            portalItem: {
              id: webmapID,
            },
          });

          view = new MapView({
            container: mapRef.current,
            map: webmap,
            extent: extent,
            zoom: 14,
            navigation: {
              mouseWheelZoomEnabled: false,
            },
          });

          view.when(() => {
            setIsMapLoaded(true); // Set the map as loaded
          });

          view.ui.add(createResponsiveLegend(Legend, view), 'top-right');
          const customSymbol = {
            type: 'picture-marker',
            url: marker,
            width: '50px',
            height: '100px',
          };

          view.graphics.add(
            new Graphic({
              geometry: {
                type: 'point',
                longitude: addressInfo?.address_longitude,
                latitude: addressInfo?.address_latitude,
              },
              symbol: customSymbol,
            }),
          );
        } else {
          const defaultSym = {
            type: 'simple-fill',
            outline: {
              color: selectedBlock?.boundaries
                ? [57, 88, 92, 1]
                : [255, 255, 255, 0],
              width: '1px',
            },
          };

          const renderer = {
            type: 'simple',
            symbol: defaultSym,
            label: `U.S. ${selectedBlock.group}`,
            visualVariables: [
              {
                type: 'color',
                field: selectedBlock?.outfields[0],
                stops: [], // Placeholder for dynamic stops
              },
            ],
          };

          const customLayer = new FeatureLayer({
            url: selectedBlock.serviceURL,
            renderer: renderer,
            title: selectedBlock.title + ` (${selectedBlock.group})`,
            popupTemplate: {
              title: selectedBlock.title + ` (${selectedBlock.group})`,
              content: `{${selectedBlock?.outfields[0]}}`,
              fieldInfos: [
                {
                  fieldName: selectedBlock?.outfields[0],
                  format: {
                    digitSeparator: true,
                    places: 0,
                  },
                },
              ],
            },
          });

          customLayer.opacity = 0.6;
          customLayer.minScale = 0;
          customLayer.maxScale = 0;

          classBreaks({
            layer: customLayer,
            field: selectedBlock?.outfields[0],
            classificationMethod: 'quantile',
          }).then(function (response) {
            let breakInfos = response.classBreakInfos;
            console.log({ breakInfos });

            const dynamicStops = breakInfos.map((info, index) => {
              return {
                value: info.maxValue,
                color:
                  index === 0
                    ? '#a8e9c7'
                    : index === 1
                      ? '#d9f0ae'
                      : index === 2
                        ? '#f9d58d'
                        : index === 3
                          ? '#f1a178'
                          : '#e66565',
                label: info.label,
              };
            });

            customLayer.renderer.visualVariables[0].stops = dynamicStops;

            const map = new Map({
              basemap: 'gray-vector',
              layers: [customLayer],
            });

            view = new MapView({
              container: mapRef.current,
              map: map,
              extent: extent,
              navigation: {
                mouseWheelZoomEnabled: false,
              },
              scale: customLayer.minScale,
            });

            view.when(() => {
              setIsMapLoaded(true); // Set the map as loaded
            });

            view.ui.add(
              new Legend({
                view: view,
              }),
              'top-right',
            );

            const customSymbol = {
              type: 'picture-marker',
              url: marker,
              width: '50px',
              height: '100px',
            };

            view.graphics.add(
              new Graphic({
                geometry: {
                  type: 'point',
                  longitude: addressInfo?.address_longitude,
                  latitude: addressInfo?.address_latitude,
                },
                symbol: customSymbol,
              }),
            );
          });
        }
      },
    );

    return () => {
      if (view) {
        view.destroy();
      }
    };
  }, [webmapID, token, selectedBlock, defaultSettings]);

  return (
    <Box
      sx={{
        width: {
          xs: '18rem',
          sm: '100%',
        },
        height: {
          xs: '15rem',
          sm: '30rem',
        },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {!isMapLoaded && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        >
          <CircularProgress
            sx={{
              color: '#4FA490',
            }}
          />
        </Box>
      )}
      <Box
        ref={mapRef}
        sx={{
          width: '100%',
          height: '100%',
          visibility: isMapLoaded ? 'visible' : 'hidden',
        }}
      />
    </Box>
  );
}

const createResponsiveLegend = (Legend, view) => {
  // Add CSS to handle responsive legend
  const style = document.createElement('style');
  style.textContent = `
    .esri-legend {
      max-height: 150px !important; /* Smaller height on mobile */
      max-width: 150px !important;  /* Smaller width on mobile */
      overflow-y: auto !important;  /* Enable scrolling if content is too long */
      overflow-x: auto !important;
      background-color: rgba(255, 255, 255, 0.9) !important; /* Semi-transparent background */
    }

    @media screen and (min-width: 600px) {
      .esri-legend {
        max-height: 300px !important; /* Larger height on desktop */
        max-width: 250px !important;  /* Larger width on desktop */
      }
    }

    .esri-legend__service {
      padding: 8px !important;
    }
  `;
  document.head.appendChild(style);

  return new Legend({
    view: view,
  });
};

export default LiveMap;
