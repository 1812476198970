export const SAMPLE_ADDRESSES = [
  '3816 Mercier St, Kansas City, MO 64111',
  '1234 Oak St Lakewood, CO 80215',
  '200 E Laurel St, Fort Collins, CO 80524',
  '3418 Roxane Blvd, Sarasota, FL 34235',
];

export const FOLLOW_UP_QUESTIONS = [
  'What is the median household income?',
  'Research new infrastructure developments',
  'Map the main stores nearby',
];

export const ADDRESS_STATUS_MESSAGES = [
  'Analyzing market trends…',
  'Looking up demographics…',
  'Researching crime…',
  'Finding local schools…',
  'Figuring out the MSA…',
  'Checking Income and Expansion data…',
  'Mapping local data…',
];

export const QUERY_STATUS_MESSAGES = [
  'Analyzing…',
  'Thinking…',
  'Writing a thoughtful response…',
];

export const MAP_OPTIONS = [
  {
    id: 'discern',
    label: 'DISCERN (default maps)',
    disabled: false,
    default: true,
  },
  {
    id: 'household',
    label: 'Household formation forecast',
    disabled: true,
    comingSoon: true,
  },
  {
    id: 'growth',
    label: 'Unit growth vs. population growth',
    disabled: true,
    comingSoon: true,
  },
  { id: 'heatmap', label: 'Rent Heatmap', disabled: true, comingSoon: true },
  { id: 'floodzone', label: 'Floodzone', disabled: true, comingSoon: true },
];
