import { Box, CircularProgress, IconButton } from '@mui/material';
import { Bot, Clock, Eye, ChevronDown, ChevronUp } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../AuthProvider';
import Header from '../../layout/Header';
import styles from './agent.module.css';
import Alert from './components/Alert';
import InitialSearchForm from './components/InitialSearchForm';
import MarkdownRenderer from './components/MarkdownRenderer';
import SearchBoxAndControls from './components/SearchBoxAndControls';
import { ADDRESS_STATUS_MESSAGES, QUERY_STATUS_MESSAGES } from './constants';
import { useChat } from './hooks/useChat';

export default function Agent() {
  const [searchParams] = useSearchParams();
  const { getAccessToken } = useAuth();
  const {
    messages,
    setMessages,
    createChatSession,
    storeChatMessage,
    setChatID,
    chatID,
  } = useChat();

  const [showChat, setShowChat] = useState(false);
  const [address, setAddress] = useState('');
  const [sideBarState, setSideBarState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [selectedMaps, setSelectedMaps] = useState(['discern']);
  const [token, setToken] = useState(null);
  const [addressInfo, setAddressInfo] = useState(null);
  const [currentStatusIndex, setCurrentStatusIndex] = useState(0);
  const [fetchingToken, setFetchingToken] = useState(true);
  const [addressError, setAddressError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [existingReportID, setExistingReportID] = useState(null);
  const [mode, setMode] = useState('analyze');
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [analyzeAddress, setAnalyzeAddress] = useState('');
  const [compareAddresses, setCompareAddresses] = useState(['', '']);
  const [selectedMapOptions, setSelectedMapOptions] = useState(['discern']);
  const [input, setInput] = useState('');
  const [compareInputs, setCompareInputs] = useState(['', '']);
  const [showThirdCompareInput, setShowThirdCompareInput] = useState(false);
  const [isDeepThinkMode, setIsDeepThinkMode] = useState(false);
  const [expandedMode, setExpandedMode] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [vestmapReport, setVestmapReport] = useState(null);
  const inputRef = useRef(null);
  const compareInputRefs = useRef([null, null, null]);
  const [showChatInput, setShowChatInput] = useState(true);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  async function getInputType(input) {
    try {
      const response = await fetch(
        'https://appchefs.app.n8n.cloud/webhook/f5ecabab-6966-454e-80a9-6e2e3cb85e38',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            input: input,
          }),
        },
      );

      const data = await response.json();
      console.log('Input type/validation response:', data);

      // Check if it's a status response (not an address)
      if (data.status && data.status === 'Likely not an address') {
        return {
          type: 'query',
          validationResult: null,
        };
      }

      // If we have result data, it's an address with validation info
      if (data.result) {
        // Store validation results for later use
        const validationResult = {
          data: data.result,
          isValid: data.result.address?.addressComponents?.every(
            component =>
              component.confirmationLevel === 'CONFIRMED' ||
              component.confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE',
          ),
          formattedAddress: data.result.address?.formattedAddress || input,
        };

        return {
          type: 'address',
          validationResult,
        };
      }

      // Default to query if no clear determination
      return {
        type: 'query',
        validationResult: null,
      };
    } catch (error) {
      console.error('Error getting input type/validation:', error);
      return {
        type: 'query',
        validationResult: null,
      }; // Default to query on error
    }
  }

  // Update validateAddress to use the validation result from getInputType
  async function validateAddress(validationResult) {
    if (!validationResult) {
      console.error('Missing validation result');
      setShowAlert(true);
      return {
        isValid: false,
        formattedAddress: null,
      };
    }

    try {
      if (!validationResult.isValid) {
        console.log('Address validation failed, showing alert');
        const unconfirmedComponents =
          validationResult.data?.address?.addressComponents?.filter(
            component =>
              component.confirmationLevel !== 'CONFIRMED' &&
              component.confirmationLevel !== 'UNCONFIRMED_BUT_PLAUSIBLE',
          );
        console.log('Unconfirmed components:', unconfirmedComponents);
        setShowAlert(true);
      } else {
        console.log('Address validation succeeded');
      }

      return {
        isValid: validationResult.isValid || false,
        formattedAddress: validationResult.formattedAddress || null,
      };
    } catch (error) {
      console.error('Error processing address validation result:', error);
      setShowAlert(true);
      return {
        isValid: false,
        formattedAddress: null,
      };
    }
  }

  function extractReportID(inputString) {
    const reportIDPattern = /\[reportID\](.*?)\[\/reportID\]/;
    const match = inputString.match(reportIDPattern);

    if (match) {
      const reportID = match[1];
      const cleanedString = inputString.replace(reportIDPattern, '');
      return { reportID, cleanedString };
    }

    return { reportID: null, cleanedString: inputString };
  }

  async function getReasoningTimeInSeconds(executionID) {
    try {
      const accessToken = await getAccessToken();
      const url = `${process.env.REACT_APP_NODE_URL}/agent/execution/${executionID}`;

      const finalPollResponse = await fetch(url + `?includeData=false`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!finalPollResponse.ok) {
        throw new Error('Failed to fetch execution data');
      }

      const finalPollData = await finalPollResponse.json();

      if (finalPollData.startedAt && finalPollData.stoppedAt) {
        const startedAt = new Date(finalPollData.startedAt);
        const stoppedAt = new Date(finalPollData.stoppedAt);
        return (stoppedAt - startedAt) / 1000;
      } else {
        throw new Error('Execution data does not contain valid timestamps');
      }
    } catch (error) {
      console.error('Error fetching reasoning time:', error);
      return null;
    }
  }

  // Main submission handler
  async function handleStandardSubmit(e) {
    e.preventDefault();
    const message = document.getElementById('chat-input').value;

    setAddressError(false);

    if (!message.trim()) return;

    setMessages(prev => [
      ...prev,
      {
        role: 'user',
        content: message,
      },
    ]);

    setIsLoading(true);

    // Get input type and validation results together
    const { type, validationResult } = await getInputType(message);
    console.log({ inputType: type, validationResult });

    if (type) {
      if (type === 'address') {
        const { isValid, formattedAddress } =
          await validateAddress(validationResult);

        // Turn off loading as we're showing the confirmation UI
        setIsLoading(false);

        if (!isValid) {
          // CASE 1: This is the first address message (This is a new chat)
          const hadPreviousAddressMessage = messages.some(
            msg => msg.role === 'assistant' && msg.type === 'address',
          );

          if (!hadPreviousAddressMessage) {
            setMessages([]);
            setShowAlert(true);
            return;
          }

          // CASE 2: There is another address message before this one (This is an old chat)
          setMessages(prev => {
            const updatedMessages = [...prev];
            if (updatedMessages[updatedMessages.length - 1].role === 'user') {
              updatedMessages[updatedMessages.length - 1].type = 'address';
            }
            return updatedMessages;
          });

          // Type 2: Invalid address - show confirmation UI with navigation option
          console.log(
            'Type 2: Invalid address - show confirmation UI with navigation option',
          );
          setMessages(prev => [
            ...prev,
            {
              role: 'assistant',
              content: '',
              type: 'address_confirmation',
              address: formattedAddress || message,
              isValid: false,
            },
          ]);

          if (!showChat) {
            setShowChat(true);
          }

          return;
        }

        // CASE 1: There is an address message in chat history (This is an old chat)
        const hadAddressType = messages.some(
          msg => msg.role === 'assistant' && msg.type === 'address',
        );

        if (hadAddressType) {
          console.log(
            'Type 3: Valid address - show confirmation UI with search option',
          );

          setMessages(prev => [
            ...prev,
            {
              role: 'assistant',
              content: '',
              type: 'address_confirmation',
              address: message,
              isValid: true,
            },
          ]);

          if (!showChat) {
            setShowChat(true);
          }

          return;
        }

        // CASE 2: This is the first address message (This is a new chat)
        console.log('Type 3: Valid address - continue with existing flow');

        await handleAddressSearch(formattedAddress);
        return;
      } else if (type === 'query') {
        const hasNoAddressType = !messages.some(
          msg => msg.role === 'assistant' && msg.type === 'address',
        );

        if (hasNoAddressType) {
          setIsLoading(false);
          setShowAlert(true);
          return;
        }

        // Type 1: Regular query - continue with existing flow
        console.log('Type 1: Regular query - continue with existing flow');
        setMessages(prev => {
          const updatedMessages = [...prev];
          updatedMessages[updatedMessages.length - 1].type = 'markdown';
          return updatedMessages;
        });

        if (!showChat) {
          setShowChat(true);
        }

        try {
          const reportContext =
            messages
              .filter(m => m.role === 'assistant' && m.type === 'address')
              .pop()?.content || '';

          // Save ongoing query details to localStorage for resumption if needed
          const queryInfo = {
            userMessage: message,
            reportContext,
            chatID: chatID || null,
            timestamp: Date.now(),
          };
          localStorage.setItem(
            'agentProcessingQuery',
            JSON.stringify(queryInfo),
          );
          localStorage.setItem('agentProcessing', true);

          const response = await fetch(
            'https://appchefs.app.n8n.cloud/webhook/65175f68-1b23-4439-8386-d29e216296ff',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                item: {
                  chatID: chatID || null,
                  chatInput: message,
                  exampleReport: {
                    text: reportContext,
                  },
                },
              }),
            },
          );

          const data = await response.json();

          const reasoningTimeInSeconds = await getReasoningTimeInSeconds(
            data.executionID,
          );

          const chatMessages = [
            {
              role: 'user',
              content: message,
              type: 'markdown',
              reasoningTime: null,
              mode: 'standard',
              addressInfo: null,
            },
            {
              role: 'assistant',
              content: data.output,
              type: 'markdown',
              reasoningTime: reasoningTimeInSeconds,
              mode: 'standard',
              addressInfo: null,
            },
          ];

          if (!chatID) {
            const chatSessionID = await createChatSession(
              chatMessages,
              selectedMaps,
            );
            setChatID(chatSessionID);
          } else {
            await storeChatMessage(chatMessages);
          }

          setMessages(prev => [
            ...prev,
            {
              role: 'assistant',
              content: data.output,
              reasoningTime: reasoningTimeInSeconds,
              type: 'markdown',
            },
          ]);
        } catch (error) {
          console.error('Error sending message:', error);
          setMessages(prev => [
            ...prev,
            {
              role: 'assistant',
              content: 'Sorry, I encountered an error processing your request.',
            },
          ]);
        } finally {
          localStorage.removeItem('agentProcessingQuery');
          localStorage.removeItem('agentProcessing');
          setIsLoading(false);

          // Clear the input field after submission
          document.getElementById('chat-input').value = '';
        }
      }
    }
  }

  // Main Address search handler
  async function handleAddressSearch(address) {
    setIsLoading(true);

    setMessages([
      {
        role: 'user',
        content: address,
        type: 'address',
        reasoningTime: null,
        mode: 'standard',
      },
    ]);

    setMessages(prev => prev.filter(m => m.type !== 'address_confirmation'));
    setAddress(address);

    let prompt = address;

    if (selectedMaps.includes('discern')) {
      const user = JSON.parse(localStorage.getItem('mongodbUser'));
      const userId = user?._id ? `Here is the user's id: ${user._id}` : '';

      // Base prompt with address
      prompt = `Get the DISCERN data for this address: ${prompt}`;

      // Add user ID if available
      if (userId) {
        prompt += `. ${userId}`;
      }
      // Add report data if available
      if (existingReportID) {
        console.log('existingReportID exists');
        try {
          const vestmapData = await getVestmapReport();

          if (vestmapData) {
            prompt += `. Here is the report ID: ${existingReportID}. Here is the report data: ${JSON.stringify(vestmapData)}`;
          } else {
            console.warn('No vestmap data received for existing report ID');
          }
        } catch (error) {
          console.error('Error fetching vestmap report data:', error);
        }
      }
    }

    console.log({ finalPrompt: prompt });

    try {
      const addressInfo = await fetchAddressInfo(address);

      const webhookResponse = await fetch(
        'https://appchefs.app.n8n.cloud/webhook/dae0b2ba-3b87-4c9d-a077-c182b4b9dfd4',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type',
          },
          body: JSON.stringify({
            chatInput: prompt,
          }),
        },
      );

      const webhookData = await webhookResponse.json();
      const executionId = webhookData[0].executionId;

      localStorage.setItem('agentProcessing', true);
      localStorage.setItem('agentProcessingAddress', address);
      localStorage.setItem('agentProcessingExecutionId', executionId);

      if (!showChat) {
        setShowChat(true);
      }

      let isFinished = false;
      let finalResponse = null;
      let reasoningTimeInSeconds = null;

      const accessToken = await getAccessToken();

      while (!isFinished) {
        await new Promise(resolve => setTimeout(resolve, 5000));

        const url =
          process.env.REACT_APP_NODE_URL + `/agent/execution/${executionId}`;
        const pollResponse = await fetch(url + `?includeData=false`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const pollData = await pollResponse.json();

        if (pollData.finished) {
          const finalPollResponse = await fetch(url + `?includeData=true`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const finalPollData = await finalPollResponse.json();

          const runData = finalPollData.data.resultData.runData;
          const lastAgentKey = Object.keys(runData).pop();
          const agentData = runData[lastAgentKey][0];
          finalResponse = agentData.data.main[0][0].json.output;

          // calculating reasoning time
          if (finalPollData.startedAt && finalPollData.stoppedAt) {
            const startedAt = new Date(finalPollData.startedAt);
            const stoppedAt = new Date(finalPollData.stoppedAt);
            reasoningTimeInSeconds = (stoppedAt - startedAt) / 1000;
          } else {
            reasoningTimeInSeconds = null;
          }

          isFinished = true;
        }
      }

      const { reportID, cleanedString } = extractReportID(finalResponse);
      console.log({ reportID, cleanedString });

      const chatMessages = [
        {
          role: 'user',
          content: address,
          type: 'address',
          reasoningTime: null,
          mode: 'standard',
          addressInfo: null,
        },
        {
          role: 'assistant',
          content: cleanedString,
          type: 'address',
          reasoningTime: reasoningTimeInSeconds,
          mode: 'standard',
          addressInfo: addressInfo || null,
        },
      ];

      console.log('creating chat session');
      const chatSessionID = await createChatSession(
        chatMessages,
        'discern',
        reportID,
      );
      console.log({ chatSessionID });
      setChatID(chatSessionID);

      setMessages(prev => [
        ...prev,
        {
          role: 'assistant',
          content: cleanedString,
          reasoningTime: reasoningTimeInSeconds,
          type: 'address',
        },
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [
        ...prev,
        {
          role: 'assistant',
          content: 'Sorry, I encountered an error processing your request.',
        },
      ]);
    } finally {
      localStorage.removeItem('agentProcessing');
      localStorage.removeItem('agentProcessingAddress');
      localStorage.removeItem('agentProcessingExecutionId');
      setIsLoading(false);
    }
  }

  /**
   * Handles searching for a new valid address
   * @param {string} address - The address to search for
   * @returns {Promise<void>}
   */
  async function typeThreeOperation(address) {
    const urlWithoutParams = window.location.pathname;
    window.history.replaceState({}, document.title, urlWithoutParams);

    setChatID(null);
    setMessages([
      {
        role: 'user',
        content: address,
        type: 'address',
        reasoningTime: null,
        mode: 'standard',
      },
    ]);
    setExistingReportID(null);
    setShowChat(false);

    // Wait for state updates to complete
    setTimeout(() => {
      document.getElementById('chat-input').value = address;
      handleAddressSearch(address);
    }, 100);
  }

  /**
   * Handles navigating to a new address for invalid addresses
   * @param {string} address - The invalid address that couldn't be validated
   * @returns {void}
   */
  async function typeTwoOperation(address) {
    const urlWithoutParams = window.location.pathname;
    window.history.replaceState({}, document.title, urlWithoutParams);

    setChatID(null);
    setMessages([
      {
        role: 'user',
        content: address,
        type: 'address',
        reasoningTime: null,
        mode: 'standard',
      },
    ]);
    setExistingReportID(null);
    setShowChat(false);

    // Wait for state updates to complete
    setTimeout(() => {
      document.getElementById('chat-input').value = address;
    }, 100);
  }

  async function fetchToken() {
    try {
      const accessToken = await getAccessToken();
      const url = process.env.REACT_APP_NODE_URL + '/admin/token';
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) throw new Error('Response error');

      const data = await response.json();
      if (data?.token) {
        setToken(data.token.access_token);

        return;
      }
      throw new Error('Token not found');
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setFetchingToken(false);
    }
  }

  async function fetchAddressInfo(address) {
    try {
      const accessToken = await getAccessToken();
      const url = process.env.REACT_APP_NODE_URL + '/agent/geocodedAddress';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ address }),
      });

      if (!response.ok) throw new Error('Response error');

      const data = await response.json();
      console.log({ data });
      setAddressInfo(data);
      return data;
    } catch (error) {
      console.error('Error fetching address info:', error);
      return null;
    }
  }

  // todo - clean these up
  const handleMapOptionChange = options => {
    setSelectedMapOptions(options);
  };

  const handleCustomMapSearch = query => {
    console.log('Searching for custom map data point:', query);
    // Implement the custom search functionality here
  };
  // Determine input placeholder based on active mode and agent mode
  const getInputPlaceholder = () => {
    if (isDeepThinkMode) {
      return 'Ask anything...';
    }

    if (hasSearched) {
      return 'Ask follow-ups about this address...';
    }

    if (mode === 'analyze') {
      return 'Enter a valid street address...';
    } else if (mode === 'compare') {
      return 'Enter first property address...';
    } else if (mode === 'discover') {
      return "Describe what you're looking for...";
    }

    return 'Message VestMap Agent...';
  };

  const suggestedFollowUps = [
    'How are the crime scores calculated?',
    'What data sources are used?',
    'Compare the income and crime sections',
  ];
  const agentModeSuggestions = [
    'Graph the last three years of median income data',
    'Map the stores nearby',
    'Research infrastructure developments',
  ];

  const toggleMode = () => {
    setIsDeepThinkMode(prev => !prev);
  };

  // Handle mode change
  const handleModeChange = newMode => {
    setMode(newMode);

    if (expandedMode === newMode) {
      setExpandedMode(null);
    } else {
      setExpandedMode(newMode);

      // Focus on appropriate input when a mode is selected
      setTimeout(() => {
        if (newMode === 'compare') {
          if (compareInputRefs.current[0]) {
            compareInputRefs.current[0].focus();
          }
        } else if (inputRef.current) {
          inputRef.current.focus();

          // If it's analyze mode, show a helpful placeholder
          if (newMode === 'analyze') {
            if (input === '') {
              setInput(''); // Just to trigger a re-render with the new placeholder
            }
          }
        }
      }, 50);
    }
  };

  async function getVestmapReport() {
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/record/${existingReportID}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (!response.ok) throw new Error('Failed to fetch vestmap report');

      const data = await response.json();
      setVestmapReport(data);
      return data;
    } catch (error) {
      console.error('Error fetching vestmap report:', error);
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    let intervalId;

    if (isLoading) {
      intervalId = setInterval(() => {
        setCurrentStatusIndex(prevIndex =>
          prevIndex === ADDRESS_STATUS_MESSAGES.length - 1 ? 0 : prevIndex + 1,
        );
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoading]);

  async function fetchChatHistory(chatID) {
    try {
      const url = `${process.env.REACT_APP_NODE_URL}/agent/chat/${chatID}`;
      const accessToken = await getAccessToken();

      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch chat history');
      }

      const data = await response.json();
      return data.messages || [];
    } catch (error) {
      console.error('Error fetching chat history:', error);
      return [];
    }
  }

  async function continueChatSession() {
    try {
      const chatID = searchParams.get('chatID');
      const address = searchParams.get('address');
      const reportID = searchParams.get('reportID');

      if (chatID || address || reportID) {
        if (chatID) {
          await fetchChatSession();
        } else if (address && reportID) {
          setExistingReportID(reportID);
          const decodedAddress = decodeURIComponent(address);
          document.getElementById('chat-input').value = decodedAddress;
        }
        return;
      }

      const agentProcessing = localStorage.getItem('agentProcessing');
      const processingAddress = localStorage.getItem('agentProcessingAddress');
      const processingExecutionId = localStorage.getItem(
        'agentProcessingExecutionId',
      );
      const processingQueryInfo = localStorage.getItem('agentProcessingQuery');

      // Handle address processing resumption
      if (agentProcessing && processingAddress && processingExecutionId) {
        // User exited page during address processing - resume where they left off
        setIsLoading(true);
        setShowChat(true);
        setAddress(processingAddress);

        // Set messages to indicate we're continuing processing
        setMessages([
          {
            role: 'user',
            content: processingAddress,
            type: 'address',
            reasoningTime: null,
            mode: 'standard',
          },
        ]);

        // Poll the execution status until finished
        const accessToken = await getAccessToken();
        let isFinished = false;
        let finalResponse = null;
        let reasoningTimeInSeconds = null;

        while (!isFinished) {
          await new Promise(resolve => setTimeout(resolve, 5000));

          const url =
            process.env.REACT_APP_NODE_URL +
            `/agent/execution/${processingExecutionId}`;
          const pollResponse = await fetch(url + `?includeData=false`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const pollData = await pollResponse.json();

          if (pollData.finished) {
            const finalPollResponse = await fetch(url + `?includeData=true`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            });
            const finalPollData = await finalPollResponse.json();

            const runData = finalPollData.data.resultData.runData;
            const lastAgentKey = Object.keys(runData).pop();
            const agentData = runData[lastAgentKey][0];
            finalResponse = agentData.data.main[0][0].json.output;

            // calculating reasoning time
            console.log({ finalPollData });
            if (finalPollData.startedAt && finalPollData.stoppedAt) {
              const startedAt = new Date(finalPollData.startedAt);
              const stoppedAt = new Date(finalPollData.stoppedAt);
              reasoningTimeInSeconds = (stoppedAt - startedAt) / 1000;
              console.log(`Reasoning started at: ${startedAt}`);
              console.log(`Reasoning stopped at: ${stoppedAt}`);
              console.log(
                `Reasoning time in seconds: ${reasoningTimeInSeconds}`,
              );
            } else {
              console.log('Execution data does not contain valid timestamps');
            }

            isFinished = true;
          }
        }

        // Process the final response
        const { reportID, cleanedString } = extractReportID(finalResponse);
        const addressInfo = await fetchAddressInfo(processingAddress);

        const chatMessages = [
          {
            role: 'user',
            content: processingAddress,
            type: 'address',
            reasoningTime: null,
            mode: 'standard',
            addressInfo: null,
          },
          {
            role: 'assistant',
            content: cleanedString,
            type: 'address',
            reasoningTime: reasoningTimeInSeconds,
            mode: 'standard',
            addressInfo: addressInfo || null,
          },
        ];

        const chatSessionID = await createChatSession(
          chatMessages,
          'discern',
          reportID,
        );
        setChatID(chatSessionID);

        setMessages(prev => [
          ...prev,
          {
            role: 'assistant',
            content: cleanedString,
            reasoningTime: reasoningTimeInSeconds,
            type: 'address',
          },
        ]);

        // Clean up local storage
        localStorage.removeItem('agentProcessing');
        localStorage.removeItem('agentProcessingAddress');
        localStorage.removeItem('agentProcessingExecutionId');
        setIsLoading(false);
      }
      // Handle query processing resumption
      else if (agentProcessing && processingQueryInfo) {
        const queryInfo = JSON.parse(processingQueryInfo);
        setIsLoading(true);
        setShowChat(true);

        if (queryInfo.chatID) {
          setChatID(queryInfo.chatID);

          // Fetch the full chat history
          const chatHistory = await fetchChatHistory(queryInfo.chatID);

          if (chatHistory.length > 0) {
            // Set messages to the full chat history plus the current processing query
            setMessages([
              ...chatHistory,
              {
                role: 'user',
                content: queryInfo.userMessage,
                type: 'markdown',
                reasoningTime: null,
                mode: 'standard',
              },
            ]);
          } else {
            // Fallback to the existing approach if history fetch fails
            setMessages([
              {
                role: 'user',
                content: queryInfo.userMessage,
                type: 'markdown',
                reasoningTime: null,
                mode: 'standard',
              },
            ]);
          }
        } else {
          // No chatID, just show the query
          setMessages([
            {
              role: 'user',
              content: queryInfo.userMessage,
              type: 'markdown',
              reasoningTime: null,
              mode: 'standard',
            },
          ]);
        }

        // Resume the query processing
        try {
          // Get data from the server with same parameters as when user left
          const response = await fetch(
            'https://appchefs.app.n8n.cloud/webhook/65175f68-1b23-4439-8386-d29e216296ff',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                item: {
                  chatID: queryInfo.chatID,
                  chatInput: queryInfo.userMessage,
                  exampleReport: {
                    text: queryInfo.reportContext,
                  },
                },
              }),
            },
          );

          const data = await response.json();

          const reasoningTimeInSeconds = await getReasoningTimeInSeconds(
            data.executionID,
          );

          const chatMessages = [
            {
              role: 'user',
              content: queryInfo.userMessage,
              type: 'markdown',
              reasoningTime: null,
              mode: 'standard',
              addressInfo: null,
            },
            {
              role: 'assistant',
              content: data.output,
              type: 'markdown',
              reasoningTime: reasoningTimeInSeconds,
              mode: 'standard',
              addressInfo: null,
            },
          ];

          if (!queryInfo.chatID) {
            const chatSessionID = await createChatSession(
              chatMessages,
              selectedMaps,
            );
            setChatID(chatSessionID);
          } else {
            setChatID(queryInfo.chatID);
            await storeChatMessage(chatMessages);
          }

          setMessages(prev => [
            ...prev,
            {
              role: 'assistant',
              content: data.output,
              reasoningTime: reasoningTimeInSeconds,
              type: 'markdown',
            },
          ]);
        } catch (error) {
          console.error('Error resuming query processing:', error);
          setMessages(prev => [
            ...prev,
            {
              role: 'assistant',
              content:
                'Sorry, I encountered an error processing your previous request.',
            },
          ]);
        } finally {
          localStorage.removeItem('agentProcessingQuery');
          localStorage.removeItem('agentProcessing');
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('Error continuing chat session:', error);
      localStorage.removeItem('agentProcessing');
      localStorage.removeItem('agentProcessingAddress');
      localStorage.removeItem('agentProcessingExecutionId');
      localStorage.removeItem('agentProcessingQuery');
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const agentProcessing = localStorage.getItem('agentProcessing');

    if (agentProcessing) {
      continueChatSession();
    } else {
      fetchChatSession();
    }
  }, []);

  useEffect(() => {
    function handleAddressAndReportID() {
      if (!fetchingToken) {
        const address = searchParams.get('address');
        const reportID = searchParams.get('reportID');
        const chatID = searchParams.get('chatID');

        if (address && reportID) {
          setExistingReportID(reportID);
          const decodedAddress = decodeURIComponent(address);
          document.getElementById('chat-input').value = decodedAddress;
        } else if (chatID) {
          fetchChatSession();
        } else {
          const agentProcessing = localStorage.getItem('agentProcessing');
          if (agentProcessing) {
            continueChatSession();
          }
        }
      }
    }

    handleAddressAndReportID();
  }, [fetchingToken, searchParams]);

  async function fetchChatSession() {
    const chatID = searchParams.get('chatID');
    if (!chatID) return;

    try {
      const accessToken = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/agent/chat-session/${chatID}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (!response.ok) throw new Error('Failed to fetch chat session');

      const data = await response.json();
      if (data.messages && data.messages.length > 0) {
        setMessages(data.messages);
        setChatID(chatID);
        setShowChat(true);
        setSelectedMaps(['discern']);
        const lastAddress = data.messages.findLast((msg, i, arr) => {
          return (
            msg.role === 'user' &&
            i < arr.length - 1 &&
            arr[i + 1].role === 'assistant' &&
            arr[i + 1].type === 'address'
          );
        })?.content;
        if (lastAddress) {
          setAddress(lastAddress);
        }
      }
    } catch (error) {
      console.error('Error fetching chat session:', error);
    }
  }

  useEffect(() => {
    fetchToken();
  }, []);

  if (fetchingToken) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {showAlert && (
        <Alert
          message='The address you entered could not be validated. Try another address.'
          onClose={() => setShowAlert(false)}
        />
      )}
      <Box
        sx={{
          paddingX: {
            md: '1.25rem',
            xs: '0',
          },
          marginBottom: '1.25rem',
        }}
      >
        <Header sideBarState={setSideBarState} pageTitle='Vestmap Agent' />
      </Box>

      {showChat ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/* X for closing (removed temporarily) */}
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0.5rem',
              borderBottom: '1px solid #e5e7eb',
              width: { xs: '95%', sm: '98.5%' },
            }}
          >
            <X
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => {
                // Remove query parameters from URL
                const urlWithoutParams = window.location.pathname;
                window.history.replaceState(
                  {},
                  document.title,
                  urlWithoutParams,
                );

                setAddressError(false);
                setChatID(null);
                setShowChat(false);
                setMessages([]);
              }}
            />
          </Box> */}
          <Box
            sx={{
              flex: 1,
              width: {
                xs: '100%',
                md: '70%',
              },
            }}
          >
            <Box
              className={styles['chat-main']}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                className={styles['chat-messages']}
                sx={{
                  flex: 1,
                  padding: { xs: '10px', sm: '20px' },
                  overflowY: 'auto',
                }}
              >
                {messages.map((message, i) => {
                  if (message.type === 'address_confirmation') {
                    return (
                      <AddressConfirmation
                        key={i}
                        address={message.address}
                        isValid={message.isValid}
                        onConfirm={() => typeThreeOperation(message.address)}
                        onNavigate={() => typeTwoOperation(message.address)}
                      />
                    );
                  }

                  return (
                    <Box
                      key={i}
                      className={`${styles.message} ${
                        message.role === 'user'
                          ? styles['message--user']
                          : styles['message--assistant']
                      }`}
                      sx={{
                        borderRadius:
                          message.role === 'assistant' ? '0.75rem' : undefined,
                        backgroundColor:
                          message.role === 'assistant' ? '#FAF9F9' : '',
                        padding:
                          message.role === 'assistant' ? '1rem' : undefined,
                        maxWidth: '100%',
                        fontSize:
                          message.role === 'user'
                            ? {
                                xs: '0.875rem',
                                sm: '1.085rem',
                              }
                            : '',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.5rem',
                        }}
                      >
                        {message.role === 'assistant' && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              gap: '0.65rem',
                              color: '#6b7280',
                              fontSize: { xs: '0.75rem', sm: '1rem' },
                            }}
                          >
                            <Clock
                              sx={{
                                color: '#6b7280',
                              }}
                            />
                            <span>
                              Reasoned for{' '}
                              {message?.reasoningTime?.toFixed(2) || 0} seconds
                            </span>
                          </Box>
                        )}

                        <Box className={styles['message__content']}>
                          {message.role === 'assistant' && (
                            <Box className={styles.avatar}>
                              <Bot
                                size={24}
                                sx={{
                                  color: 'white',
                                }}
                              />
                            </Box>
                          )}
                          <Box
                            className={styles['message__bubble']}
                            sx={{
                              backgroundColor:
                                message.role === 'user' ? 'black' : '',
                              color: message.role === 'user' ? 'white' : '#fff',
                              fontSize: { xs: '0.875rem', sm: '1rem' },
                              padding: { xs: '0.75rem', sm: '1rem' },
                              maxWidth: '100%',
                            }}
                          >
                            {message.role === 'assistant' ? (
                              <MarkdownRenderer
                                text={message.content}
                                addressInfo={
                                  message?.addressInfo || addressInfo
                                }
                                token={token}
                              />
                            ) : (
                              message.content
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                {isLoading && (
                  <Box className={styles.message}>
                    <Box className={styles['message__content']}>
                      <Box className={styles.avatar}>
                        <Bot
                          size={24}
                          sx={{
                            color: 'white',
                          }}
                        />
                      </Box>
                      <Box className={styles['typing-indicator']}>
                        <Box className={styles['typing-indicator__dot']}></Box>
                        <Box className={styles['typing-indicator__dot']}></Box>
                        <Box className={styles['typing-indicator__dot']}></Box>

                        <Box
                          sx={{
                            left: '50%',
                            fontSize: { xs: '0.75rem', sm: '0.875rem' },
                            color: '#6b7280',
                          }}
                        >
                          {messages.length > 0 &&
                          messages[messages.length - 1].role === 'user' &&
                          messages[messages.length - 1].type === 'address'
                            ? ADDRESS_STATUS_MESSAGES[currentStatusIndex]
                            : QUERY_STATUS_MESSAGES[currentStatusIndex]}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box ref={messagesEndRef} />
              </Box>

              {/* Placeholder */}
              <Box
                sx={{
                  maxWidth: '48rem',
                  margin: '0 auto',
                  padding: { xs: '1rem', sm: '1.5rem' },
                  height: {
                    xs: showChatInput ? '10rem' : '2rem',
                    sm: '18rem',
                    md: '13rem',
                  },
                }}
              ></Box>

              {/* Chat Input */}
              <Box
                sx={{
                  boxSizing: 'border-box',
                  margin: '0 auto',
                  paddingX: { xs: '0.75rem', sm: '1.5rem' },
                  paddingY: '0.5rem',
                  position: 'fixed',
                  bottom: 0,

                  left: {
                    xs: 0,
                    sm: '5%',
                    md: '15%',
                    lg: '20%',
                  },
                  width: {
                    xs: '100%',
                    sm: '90%',
                    md: '85%',
                    lg: '78%',
                  },
                  backgroundColor: 'white',
                  boxShadow: '0 -2px 10px rgba(0,0,0,0.05)',
                  transition: 'transform 0.3s ease-in-out',
                  display: showChatInput ? 'block' : 'none',
                }}
              >
                <SearchBoxAndControls
                  // Mode related props
                  mode={mode}
                  expandedMode={expandedMode}
                  isDeepThinkMode={isDeepThinkMode}
                  hasSearched={hasSearched}
                  showThirdCompareInput={showThirdCompareInput}
                  // Input related props
                  input={input}
                  setInput={setInput}
                  compareInputs={compareInputs}
                  // Map selection props
                  selectedMapOptions={selectedMaps}
                  onCustomMapSearch={() => {}} // Implement if needed
                  // Event handlers
                  handleSend={handleStandardSubmit}
                  handleModeChange={handleModeChange}
                  toggleMode={toggleMode}
                  // Data props
                  suggestedAddresses={[
                    'Explain the demographic group.',
                    'Tell me about the schools around this property.',
                    'How is the growth and expansion in this area?',
                  ]}
                  suggestedFollowUps={suggestedFollowUps}
                  agentModeSuggestions={agentModeSuggestions}
                  // Utility props
                  getInputPlaceholder={getInputPlaceholder}
                  isLoading={isLoading}
                  functionButtonsFlag={false}
                  chatOpen={true}
                  address={address}
                  autocompletion={false}
                  showChatInput={showChatInput}
                  setShowChatInput={setShowChatInput}
                />
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  bottom: '1rem',
                  left: '45%',
                  display: showChatInput ? 'none' : 'block',
                }}
              >
                <IconButton
                  onClick={() => setShowChatInput(true)}
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    height: '2rem',
                    width: '2rem',
                  }}
                >
                  <ChevronUp />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <InitialSearchForm
          handleSubmit={handleStandardSubmit}
          addressError={addressError}
          setAddressError={setAddressError}
          selectedMaps={selectedMaps}
          onMapSelect={mapId => {
            setSelectedMaps(prev =>
              prev.includes(mapId)
                ? prev.filter(id => id !== mapId)
                : [...prev, mapId],
            );
          }}
          isLoading={isLoading}
          ///
          initialMode={mode}
          onModeChange={setMode}
          analyzeAddress={analyzeAddress}
          onAnalyzeAddressChange={setAnalyzeAddress}
          compareAddresses={compareAddresses}
          onCompareAddressesChange={setCompareAddresses}
          selectedStates={selectedStates}
          onSelectedStatesChange={setSelectedStates}
          selectedPersona={selectedPersona}
          onSelectedPersonaChange={setSelectedPersona}
          selectedMapOptions={selectedMapOptions}
          onMapOptionChange={handleMapOptionChange}
          onCustomMapSearch={handleCustomMapSearch}
        />
      )}
    </div>
  );
}

function AddressConfirmation({ address, isValid, onConfirm, onNavigate }) {
  return (
    <div className={styles.message}>
      <div className={styles['message__content']}>
        <div className={styles.avatar}>
          <Bot size={24} style={{ color: 'white' }} />
        </div>
        <div
          className={styles['message__bubble']}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {isValid ? (
            <>
              <p>
                Are you trying to search this address?{' '}
                <strong>{address}</strong>
              </p>
              <button
                onClick={onConfirm}
                style={{
                  backgroundColor: '#000',
                  color: 'white',
                  padding: '0.5rem 1rem',
                  borderRadius: '0.5rem',
                  border: 'none',
                  cursor: 'pointer',
                  alignSelf: 'flex-start',
                }}
              >
                Search address
              </button>
            </>
          ) : (
            <>
              <p>
                Are you trying to search a new address?{' '}
                <strong>{address}</strong>
              </p>
              <button
                onClick={onNavigate}
                style={{
                  backgroundColor: '#000',
                  color: 'white',
                  padding: '0.5rem 1rem',
                  borderRadius: '0.5rem',
                  border: 'none',
                  cursor: 'pointer',
                  alignSelf: 'flex-start',
                }}
              >
                Try new address
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
