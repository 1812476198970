import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  maxWidth: '85%',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2.5),
  backgroundColor: isUser
    ? theme.palette.primary.main
    : theme.palette.grey[100],
  color: isUser
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
}));

const SuggestionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: 24,
  textTransform: 'none',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey[100]}`,
  color: 'black',
  fontSize: 14,
  whiteSpace: 'nowrap',
  marginBottom: theme.spacing(0.75),
  boxShadow: theme.shadows[1],
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
  },
}));

const ModeToggle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[100],
  borderRadius: 20,
  padding: theme.spacing(0.5),
  position: 'relative',
  cursor: 'pointer',
  width: 240,
}));

const ToggleIndicator = styled(Box)(({ theme, isDeepThinkMode }) => ({
  position: 'absolute',
  top: 4,
  left: isDeepThinkMode ? 'calc(50% + 4px)' : 4,
  width: 'calc(50% - 8px)',
  height: 'calc(100% - 8px)',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 16,
  boxShadow: theme.shadows[1],
  transition: 'all 0.2s ease-in-out',
}));

const ToggleOption = styled(Box)(({ theme, isActive }) => ({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50%',
  padding: theme.spacing(0.75),
  fontSize: 12,
  fontWeight: 500,
  borderRadius: 16,
  color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
  transition: 'color 0.2s ease-in-out',
}));

const FunctionButton = styled(Button)(({ theme, active, expanded }) => ({
  borderRadius: 24,
  padding: theme.spacing(1, 2),
  boxShadow: theme.shadows[1],
  backgroundColor:
    active || expanded
      ? 'rgba(64, 196, 170, 0.05)'
      : theme.palette.background.paper,
  borderColor: '#4FA490',
  color: '#4FA490',
  '&:hover': {
    borderColor: '#4FA490',
    backgroundColor: 'rgba(79, 164, 144, 0.05)',
  },
  width: 150,
}));

export {
  MessageBubble,
  SuggestionButton,
  ModeToggle,
  ToggleIndicator,
  ToggleOption,
  FunctionButton,
};
