import { loadModules } from 'esri-loader';
import { useRef, useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useAuth } from '../../../AuthProvider';

function ReportModal({ isOpen, onClose, reportData }) {
  const { getAccessToken } = useAuth();
  const [selectedReport, setSelectedReport] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchReportData() {
      try {
        setLoading(true);
        const url = `${process.env.REACT_APP_NODE_URL}/record/${reportData._id}`;
        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`,
          },
        });
        const data = await response.json();
        if (data) {
          setSelectedReport(data);
        }
      } catch (error) {
        console.error('Error fetching report data:', error);
      } finally {
        setLoading(false);
      }
    }

    if (reportData) {
      fetchReportData();
    }
  }, [reportData]);

  const handleModalOpen = id => {
    const url = `${window.location.origin}/report/${id}`;
    window.open(url, '_blank');
  };

  if (!reportData) return null;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='report-modal-title'
      aria-describedby='report-modal-description'
    >
      {loading ? (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '2rem',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <VestmapBox
          record={selectedReport || null}
          handleModalOpen={handleModalOpen}
          id={selectedReport?._id}
          key={selectedReport?._id}
        />
      )}
    </Modal>
  );
}

export default function MapView() {
  const { getAccessToken } = useAuth();
  const [coordinates, setCoordinates] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NODE_URL}/admin/vestmap-coordinates?all=true`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${getAccessToken()}`,
            },
          },
        );
        const data = await response.json();
        if (data.status === 'Success') {
          console.log({ vestmaps: data.vestmaps });
          setCoordinates(data.vestmaps);
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };

    fetchCoordinates();
  }, []);
  const mapRef = useRef(null);

  useEffect(() => {
    let view;
    loadModules(['esri/Map', 'esri/views/MapView', 'esri/Graphic']).then(
      ([Map, MapView, Graphic]) => {
        const map = new Map({
          basemap: 'streets-navigation-vector',
        });

        view = new MapView({
          container: mapRef.current,
          map: map,
          center: [-100.33, 25.69],
          zoom: 3,
        });

        coordinates.forEach(item => {
          const point = {
            type: 'point',
            longitude: item.addressInfo.address_longitude,
            latitude: item.addressInfo.address_latitude,
          };

          const markerSymbol = {
            type: 'simple-marker',
            color: [226, 119, 40],
            outline: {
              color: [255, 255, 255],
              width: 1,
            },
          };

          const pointGraphic = new Graphic({
            geometry: point,
            symbol: markerSymbol,
          });

          view.graphics.add(pointGraphic);
        });

        view.on('click', event => {
          view.hitTest(event).then(response => {
            if (response.results.length) {
              const graphic = response.results.filter(
                result => result.graphic,
              )[0].graphic;
              const item = coordinates.find(
                coord =>
                  coord.addressInfo.address_longitude ===
                    graphic.geometry.longitude &&
                  coord.addressInfo.address_latitude ===
                    graphic.geometry.latitude,
              );
              if (item) {
                setSelectedReport(item);
                setIsModalOpen(true);
              }
            }
          });
        });
      },
    );

    return () => {
      if (view) {
        view.destroy();
      }
    };
  }, [coordinates]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };
  return (
    <>
      <div ref={mapRef} style={{ height: '87vh', width: '100%' }}></div>
      <ReportModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        reportData={selectedReport}
      />
    </>
  );
}

const VestmapBox = props => {
  return (
    <Box
      key={props.id}
      sx={{
        alignSelf: 'center',
        '@media (max-width: 600px)': {
          width: '100%',
        },
        '@media only screen and (min-width: 600px) and (max-width: 1023px)': {
          width: '49%',
        },
        '@media only screen and (min-width: 1024px)': {
          width: '32%',
        },
        borderRadius: '1.21144rem',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginY: '1rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '150px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={
            props?.record?.assets?.maps?.google_map ||
            `https://oldapp.vestmap.com/google_img/${props?.record?.google_image_url}`
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = require('../../../img/defaultMap.png');
          }}
          alt='Vestmap Default Map'
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
          }}
        />
      </Box>

      {props?.record?.template && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <Box
            sx={[
              {
                fontFamily: 'Lato',
                fontSize: '1rem',
                padding: '0.5rem 0.5rem 0.5rem 0.5rem',
                borderRadius: '0.7rem',
              },

              props?.record?.template?.theme,
            ]}
          >
            {props?.record?.template?.templateName}
          </Box>
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          padding: '1.25rem 0',
          textAlign: 'center',
        }}
      >
        <Typography
          variant='h6'
          component='h3'
          textAlign={'center'}
          sx={{
            fontFamily: '"Lato", sans-serif',
            paddingX: '1rem',
            fontSize: '1.25rem',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '140%',
            color: '#323142',
          }}
        >
          {props?.record?.address_info?.address ||
            props?.record?.addressInfo?.address}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          marginBottom: '1.5rem',
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <Button
          target='_blank'
          startIcon={<LaunchIcon />}
          variant='outlined'
          size='large'
          onClick={() => props.handleModalOpen(props.record._id)}
          sx={{
            border: '2px solid #39585C',
            borderRadius: '0.75rem',
            color: 'black',
            textTransform: 'capitalize',
            fontFamily: '"Lato", sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '150%',
            ':hover': {
              border: '2px solid #39585C',
              bgcolor: '#39585C',
              color: 'white',
            },
          }}
        >
          View Report
        </Button>
      </Box>
    </Box>
  );
};
