import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useMobileRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      navigate('/coming-soon');
    }
  }, [navigate]);
};

export function useDesktopRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      navigate('/run-reports');
    }
  }, [navigate]);
}

export default useMobileRedirect;
