import { Laptop, Clock } from 'lucide-react';
import { useDesktopRedirect } from '../../../hooks/useMobileRedirect';
import Header from '../../layout/Header';
import { Box } from '@mui/material';
import { useState } from 'react';

const ComingSoon = () => {
  const [sideBarState, setSideBarState] = useState(false);

  useDesktopRedirect();

  return (
    <Box>
      <Box
        sx={{
          paddingX: {
            md: '1.25rem',
            xs: '0',
          },
        }}
      >
        <Header sideBarState={setSideBarState} pageTitle='Coming Soon' />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
          textAlign: 'center',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#f8f9fa',
          color: '#333',
        }}
      >
        <Clock
          sx={{
            width: '60px',
            height: '60px',
            marginBottom: '20px',
            color: '#39585C',
          }}
        />

        <Box
          component='h1'
          sx={{
            fontSize: '28px',
            marginBottom: '16px',
            fontWeight: 'bold',
          }}
        >
          Coming soon!
        </Box>

        <Box
          component='p'
          sx={{
            fontSize: '16px',
            lineHeight: '1.5',
            marginBottom: '24px',
          }}
        >
          Please open on a laptop or desktop to continue.
        </Box>

        <Laptop
          sx={{
            width: '40px',
            height: '40px',
            color: '#39585C',
          }}
        />
      </Box>
    </Box>
  );
};

export default ComingSoon;
