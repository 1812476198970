import { useState } from 'react';
import { useAuth } from '../../../../AuthProvider';

export function useChat() {
  const { getAccessToken } = useAuth();
  const [chatID, setChatID] = useState(null);
  const [messages, setMessages] = useState([]);

  const createChatSession = async (
    messages,
    selectedMaps = 'discern',
    reportID,
  ) => {
    const accessToken = await getAccessToken();
    console.log({ messages, selectedMaps, reportID });
    try {
      const url = process.env.REACT_APP_NODE_URL + '/agent/chat-session';
      const user = JSON.parse(localStorage.getItem('mongodbUser'));
      const userID = user._id;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ userID, messages, selectedMaps , reportID }),
      });

      const data = await response.json();
      console.log({data})
      setChatID(data.chatSessionID);
      return data.chatSessionID;
    } catch (error) {
      console.error('Error creating chat session:', error);
      return null;
    }
  };

  const storeChatMessage = async (messages, reportID) => {
    try {
      const accessToken = await getAccessToken();
      console.log({ messages,  reportID });

      const url = process.env.REACT_APP_NODE_URL + '/agent/chat-session';
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ messages, chatSessionID: chatID, reportID }),
      });
      if (!response.ok) throw new Error('Response error');
      return true;
    } catch (error) {
      console.error('Error storing chat message:', error);
      return false;
    }
  };

  return {
    messages,
    setMessages,
    chatID,
    createChatSession,
    storeChatMessage,
    setChatID,
  };
}
