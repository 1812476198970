import { React, useState, useEffect } from 'react';
import Content from '../Content';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../AuthProvider';

const RunReports = () => {
  const { user, getAccessToken } = useAuth();
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');

  const fetchUser = async outsettaID => {
    console.log({ outsettaID });
    try {
      const url = new URL(
        process.env.REACT_APP_NODE_URL + '/user_data/' + outsettaID,
      );
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });

      if (response.status === 404) {
        navigate('/in-progress');
      }

      const data = await response.json();
      const { user } = data;
      localStorage.setItem('mongodbUser', JSON.stringify(user));
      setUserId(user._id);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    console.log({ user });
    const outsettaUserID = user?.Uid;
    fetchUser(outsettaUserID);
  }, []);

  if (!userId) return null;
  return <Content userId={userId} />;
};

export default RunReports;
