import React, { useEffect } from 'react';
import { X } from 'lucide-react';

const Alert = ({ message, onClose }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 4000);

    return () => clearTimeout(timeout);
  }, [onClose]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#fee2e2',
        color: '#991b1b',
        padding: '1rem',
        borderRadius: '0.375rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        zIndex: 50,
        maxWidth: '90%',
        width: 'auto',
      }}
    >
      <span>{message}</span>
      {onClose && (
        <button
          onClick={onClose}
          style={{
            background: 'none',
            border: 'none',
            padding: '0.25rem',
            cursor: 'pointer',
            color: '#991b1b',
          }}
        >
          <X size={16} />
        </button>
      )}
    </div>
  );
};

export default Alert;
