import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ArrowUp, Brain, Plus, EyeOff, ChevronDown } from 'lucide-react';
import { useRef, useEffect, useMemo } from 'react';
import {
  ModeToggle,
  ToggleIndicator,
  ToggleOption,
  SuggestionButton,
  FunctionButton,
} from './StyledComponents';
import { MapOptionsDropdown } from './MapSelection';
import { CircularProgress } from '@mui/material';

export default function SearchBoxAndControls({
  mode,
  expandedMode,
  hasSearched,
  input,
  setInput,
  compareInputs,
  handleCompareInputChange,
  isDeepThinkMode,
  selectedMapOptions,
  onMapOptionChange,
  onCustomMapSearch,
  handleSend,
  handleModeChange,
  toggleMode,
  showThirdCompareInput,
  handleAddThirdCompareInput,
  functionButtons,
  suggestedAddresses,
  suggestedFollowUps,
  agentModeSuggestions,
  getInputPlaceholder,
  isLoading,
  setShowChatInput,
  showChatInput,
  functionButtonsFlag = true,
  chatOpen = false,
  address = null,
  autocompletion = true,
}) {
  const inputRef = useRef(null);
  const compareInputRefs = useRef([]);

  const autoCompleteRef = useRef();
  const options = useMemo(
    () => ({
      componentRestrictions: { country: 'us' },
      fields: [
        'address_components',
        'geometry',
        'icon',
        'name',
        'formatted_address',
      ],
    }),
    [],
  );

  useEffect(() => {
    if (!autocompletion) return undefined;
    if (!window.google) return undefined;

    if (autoCompleteRef.current) {
      window.google.maps.event.clearInstanceListeners(autoCompleteRef.current);
    }

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options,
    );

    autoCompleteRef.current.addListener('place_changed', () => {
      const place = autoCompleteRef.current.getPlace();
      const address = place.formatted_address;
      if (mode === 'compare') {
        handleCompareInputChange(0, address);
      } else {
        setInput(address);
      }
    });

    return () => {
      if (autoCompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(
          autoCompleteRef.current,
        );
      }
    };
  }, [mode, setInput, handleCompareInputChange, options]);

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {/* Function Buttons - Only shown on initial screen */}
      {!hasSearched && functionButtonsFlag && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: { xs: 1, sm: 2 },
            mb: 1.5,
            flexWrap: { xs: 'wrap', sm: 'nowrap' },
          }}
        >
          {functionButtons.map(button => (
            <Tooltip
              key={button.label}
              title={button.tooltip}
              placement='top'
              arrow
            >
              <FunctionButton
                variant='outlined'
                active={mode === button.mode ? 1 : 0}
                expanded={expandedMode === button.mode ? 1 : 0}
                startIcon={button.icon && <button.icon />}
                onClick={() =>
                  button.onClick
                    ? button.onClick()
                    : handleModeChange(button.mode)
                }
                disabled={button.disabled}
                sx={{
                  fontSize: { xs: '0.75rem', sm: '0.85rem' },
                  padding: { xs: '6px 10px', sm: '0.5rem 0.75rem' },
                }}
              >
                {button.label}
              </FunctionButton>
            </Tooltip>
          ))}
        </Box>
      )}
      {/* for mobile devices show a small button that toggles show chat input */}
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'center',
        }}
      >
        {chatOpen === true && (
          <IconButton
            onClick={() => setShowChatInput(false)}
            sx={{
              backgroundColor: 'white',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              height: '2rem',
              width: '2rem',
              position: 'absolute',
              top: '-3rem',
            }}
          >
            <ChevronDown />
          </IconButton>
        )}
      </Box>
      {/* Search Box Container */}
      <Box
        sx={{
          mx: 'auto',
          width: '100%',
          p: { xs: 1, sm: 2, md: 2.5 },
          boxSizing: 'border-box',
        }}
      >
        <Paper elevation={2} sx={{ borderRadius: 4, overflow: 'hidden' }}>
          {/* Standard Search Input with Maps Dropdown */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* Main Input Field */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                id='chat-input'
                inputRef={inputRef}
                // value={mode === 'compare' ? compareInputs[0] : input}
                // onChange={e => {
                //   const value = e.target.value;
                //   if (mode === 'compare') {
                //     handleCompareInputChange(0, value);
                //   } else {
                //     setInput(value);
                //   }
                // }}
                placeholder={
                  isDeepThinkMode
                    ? 'Coming Soon!'
                    : chatOpen === true
                      ? address
                        ? `Ask a question about ${address}`
                        : getInputPlaceholder()
                      : getInputPlaceholder()
                }
                fullWidth
                variant='standard'
                disabled={isDeepThinkMode || isLoading}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    py: { xs: 2, sm: 3, md: 3.5 },
                    px: { xs: 1.5, sm: 2, md: 2.5 },
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                  },
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    if (mode === 'compare') {
                      compareInputRefs.current[1]?.focus();
                    } else {
                      handleSend(e);
                      setInput('');
                    }
                  }
                }}
              />

              {(mode !== 'compare' ||
                (mode === 'compare' && showThirdCompareInput)) && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: 0.5, sm: 1 },
                    pr: { xs: 1, sm: 2 },
                  }}
                >
                  <Box sx={{ position: 'relative' }}>
                    <MapOptionsDropdown
                      selectedOptions={selectedMapOptions}
                      onOptionChange={onMapOptionChange}
                      onCustomSearch={onCustomMapSearch}
                    />
                  </Box>

                  <IconButton
                    onClick={e => {
                      handleSend(e);
                      setInput('');
                    }}
                    disabled={isDeepThinkMode}
                    sx={{
                      bgcolor: '#4FA490',
                      color: 'white',
                      '&:hover': { bgcolor: '#4FA490' },
                      width: 40,
                      height: 40,
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color='inherit' />
                    ) : (
                      <ArrowUp />
                    )}
                  </IconButton>
                </Box>
              )}
            </Box>

            {/* Compare Mode Inputs */}
            {mode === 'compare' && (
              <>
                <CompareInputField
                  index={1}
                  compareInputs={compareInputs}
                  compareInputRefs={compareInputRefs}
                  handleCompareInputChange={handleCompareInputChange}
                  showThirdCompareInput={showThirdCompareInput}
                  selectedMapOptions={selectedMapOptions}
                  onMapOptionChange={onMapOptionChange}
                  onCustomMapSearch={onCustomMapSearch}
                  handleSend={handleSend}
                />

                {showThirdCompareInput && (
                  <CompareInputField
                    index={2}
                    compareInputs={compareInputs}
                    compareInputRefs={compareInputRefs}
                    handleCompareInputChange={handleCompareInputChange}
                    handleSend={handleSend}
                  />
                )}

                {!showThirdCompareInput && (
                  <AddPropertyButton onClick={handleAddThirdCompareInput} />
                )}
              </>
            )}
          </Box>

          {/* Mode Toggle */}
          <ModeToggleSection
            isDeepThinkMode={isDeepThinkMode}
            toggleMode={toggleMode}
          />
        </Paper>
      </Box>
      {/* Suggestions */}
      <Box
        sx={{
          display: {
            xs: chatOpen && 'none',
            md: 'block',
          },
        }}
      >
        <SuggestionsSection
          isDeepThinkMode={isDeepThinkMode}
          hasSearched={hasSearched}
          mode={mode}
          agentModeSuggestions={agentModeSuggestions}
          suggestedFollowUps={suggestedFollowUps}
          suggestedAddresses={suggestedAddresses}
          setInput={setInput}
          handleCompareInputChange={handleCompareInputChange}
          compareInputs={compareInputs}
        />
      </Box>
    </Box>
  );
}

// Sub-components
function CompareInputField({
  index,
  compareInputs,
  compareInputRefs,
  handleCompareInputChange,
  showThirdCompareInput,
  selectedMapOptions,
  onMapOptionChange,
  onCustomMapSearch,
  handleSend,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderTop: 1,
        borderColor: 'divider',
        mt: 0.5,
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <TextField
        inputRef={el => (compareInputRefs.current[index] = el)}
        value={compareInputs[index] || ''}
        onChange={e => handleCompareInputChange(index, e.target.value)}
        placeholder={`Enter ${['second', 'third'][index - 1]} property address...`}
        fullWidth
        variant='standard'
        InputProps={{
          disableUnderline: true,
          sx: {
            py: { xs: 2, sm: 3, md: 3.5 },
            px: { xs: 1.5, sm: 2, md: 2.5 },
            fontSize: { xs: '0.875rem', sm: '1rem' },
          },
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            if (showThirdCompareInput && index === 1) {
              compareInputRefs.current[2]?.focus();
            } else {
              handleSend();
            }
          }
        }}
      />
      {/* Add MapOptionsDropdown and Send button for second input when third input isn't shown */}
      {index === 1 && !showThirdCompareInput && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: 0.5, sm: 1 },
            pr: { xs: 1, sm: 2 },
            width: { xs: '100%', sm: 'auto' },
            justifyContent: { xs: 'flex-end', sm: 'flex-start' },
            py: { xs: 1, sm: 0 },
          }}
        >
          <MapOptionsDropdown
            selectedOptions={selectedMapOptions}
            onOptionChange={onMapOptionChange}
            onCustomSearch={onCustomMapSearch}
          />
          <IconButton
            onClick={handleSend}
            disabled={
              compareInputs[0].trim() === '' || compareInputs[1].trim() === ''
            }
            sx={{
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': { bgcolor: 'primary.dark' },
              width: { xs: 36, sm: 40 },
              height: { xs: 36, sm: 40 },
            }}
          >
            <ArrowUp />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

function AddPropertyButton({ onClick }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        py: 1.5,
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <Button
        variant='text'
        size='small'
        startIcon={<Plus fontSize='small' />}
        onClick={onClick}
        sx={{ fontSize: 12, color: 'text.secondary' }}
      >
        Add another address
      </Button>
    </Box>
  );
}

function ModeToggleSection({ isDeepThinkMode, toggleMode }) {
  return (
    <Box
      sx={{
        px: { xs: 1.5, sm: 2.5 },
        py: { xs: 1, sm: 1.5 },
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <ModeToggle onClick={toggleMode}>
        <ToggleIndicator isDeepThinkMode={isDeepThinkMode} />
        <ToggleOption isActive={!isDeepThinkMode}>Standard</ToggleOption>
        <ToggleOption isActive={isDeepThinkMode}>
          <Brain style={{ fontSize: { xs: 12, sm: 14 }, marginRight: 2 }} />
          Agent Mode
        </ToggleOption>
      </ModeToggle>
      <Typography
        variant='caption'
        color='text.secondary'
        sx={{
          display: 'block',
          mt: 0.5,
          ml: 0.5,
          fontSize: { xs: '0.65rem', sm: '0.75rem' },
        }}
      >
        {isDeepThinkMode
          ? 'Advanced experience enabled. May make mistakes.'
          : 'Standard mode. For address lookup only.'}
      </Typography>
    </Box>
  );
}

function SuggestionsSection({
  isDeepThinkMode,
  hasSearched,
  mode,
  agentModeSuggestions,
  suggestedFollowUps,
  suggestedAddresses,
  setInput,
  handleCompareInputChange,
  compareInputs,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: { xs: 1, sm: 1.5 },
        mt: 0.25,
        flexWrap: 'wrap',
        px: { xs: 1, sm: 0 },
      }}
    >
      {isDeepThinkMode
        ? agentModeSuggestions.map((question, index) => (
            <SuggestionButton
              key={index}
              onClick={() => {
                document.getElementById('chat-input').value = question;
                setInput(question);
              }}
              variant='outlined'
              size='small'
              sx={{
                fontSize: { xs: '0.7rem', sm: 'inherit' },
                py: { xs: 0.5, sm: 'inherit' },
              }}
            >
              {question}
            </SuggestionButton>
          ))
        : hasSearched
          ? suggestedFollowUps.map((question, index) => (
              <SuggestionButton
                key={index}
                onClick={() => {
                  document.getElementById('chat-input').value = question;
                  setInput(question);
                }}
                variant='outlined'
                size='small'
                sx={{
                  fontSize: { xs: '0.7rem', sm: '0.85rem' },
                  py: { xs: 0.5, sm: 'inherit' },
                }}
              >
                {question}
              </SuggestionButton>
            ))
          : suggestedAddresses.map((address, index) => (
              <SuggestionButton
                key={index}
                onClick={() =>
                  mode === 'compare'
                    ? handleCompareInputChange(
                        compareInputs[0] ? 1 : 0,
                        address,
                      )
                    : (document.getElementById('chat-input').value = address)
                }
                variant='outlined'
                size='small'
                sx={{
                  fontSize: { xs: '0.7rem', sm: '0.85rem' },
                  py: { xs: 0.5, sm: '0.5rem' },
                  px: { xs: 1, sm: 3 },
                }}
              >
                {address}
              </SuggestionButton>
            ))}
    </Box>
  );
}
